
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'CheckboxRichText',
    mixins: [ScreenSize],
    props: {
      content: {
        type: String,
        required: false,
        default: null,
      },
    },
    computed: {
      dynamicComponent: function () {
        return {
          template: `<div>${this.replaceLinks(
            this.$md.render(this.content)
          )}</div>`,
        };
      },
    },
    methods: {
      replaceLinks: function (value) {
        return value.replace(/<a(\S*)/g, "<a @click.stop target='_blank'");
      },
    },
  };
